define('keygen/routes/users/index', ['exports', 'keygen/routes/users'], function (exports, _keygenRoutesUsers) {
  exports['default'] = _keygenRoutesUsers['default'].extend({
    queryParams: {
      'activity[inside]': { refreshModel: true },
      'activity[outside]': { refreshModel: true },
      'activity[before]': { refreshModel: true },
      'activity[after]': { refreshModel: true },
      page: { refreshModel: true },
      size: { refreshModel: true },
      assigned: { refreshModel: true },
      active: { refreshModel: true },
      product: { refreshModel: true },
      license: { refreshModel: true },
      group: { refreshModel: true },
      roles: { refreshModel: true },
      status: { refreshModel: true }
    },

    titleToken: function titleToken() {
      return 'Users';
    },

    model: function model(params, transition) {
      var activity = undefined;
      var metadata = undefined;
      var number = params.page;
      var size = params.size;
      var assigned = params.assigned;
      var active = params.active;
      var product = params.product;
      var license = params.license;
      var group = params.group;
      var roles = params.roles;
      var status = params.status;

      switch (true) {
        case params['activity[inside]'] != null:
          activity = { inside: params['activity[inside]'] };
          break;
        case params['activity[outside]'] != null:
          activity = { outside: params['activity[outside]'] };
          break;
        case params['activity[before]'] != null:
          activity = { before: params['activity[before]'] };
          break;
        case params['activity[after]'] != null:
          activity = { after: params['activity[after]'] };
          break;
      }

      // parse arbitrary metadata keys from transition query
      for (var param in transition.queryParams) {
        if (!param.startsWith('metadata[')) {
          continue;
        }

        if (metadata == null) {
          metadata = {};
        }

        var key = param.replace(/^metadata\[(.+)\]$/, '$1');
        var value = transition.queryParams[param];

        metadata[key] = value;
      }

      if (roles != null) {
        roles = roles.split(',');
      }

      return this.store.query('user', {
        page: { number: number, size: size },
        assigned: assigned,
        active: active,
        product: product,
        license: license,
        group: group,
        roles: roles,
        status: status,
        activity: activity,
        metadata: metadata
      });
    }
  });
});