define('keygen/routes/licenses/index', ['exports', 'keygen/routes/licenses'], function (exports, _keygenRoutesLicenses) {
  exports['default'] = _keygenRoutesLicenses['default'].extend({
    queryParams: {
      'activations[eq]': { refreshModel: true },
      'activations[gt]': { refreshModel: true },
      'activations[gte]': { refreshModel: true },
      'activations[lt]': { refreshModel: true },
      'activations[lte]': { refreshModel: true },
      'expires[before]': { refreshModel: true },
      'expires[after]': { refreshModel: true },
      'expires[in]': { refreshModel: true },
      'activity[inside]': { refreshModel: true },
      'activity[outside]': { refreshModel: true },
      'activity[before]': { refreshModel: true },
      'activity[after]': { refreshModel: true },
      page: { refreshModel: true },
      size: { refreshModel: true },
      suspended: { refreshModel: true },
      expiring: { refreshModel: true },
      expired: { refreshModel: true },
      unassigned: { refreshModel: true },
      assigned: { refreshModel: true },
      activated: { refreshModel: true },
      status: { refreshModel: true },
      product: { refreshModel: true },
      policy: { refreshModel: true },
      group: { refreshModel: true },
      user: { refreshModel: true },
      machine: { refreshModel: true }
    },

    titleToken: function titleToken() {
      return 'Licenses';
    },

    model: function model(params, transition) {
      var activations = undefined;
      var activity = undefined;
      var expires = undefined;
      var metadata = undefined;
      var number = params.page;
      var size = params.size;
      var suspended = params.suspended;
      var expiring = params.expiring;
      var expired = params.expired;
      var unassigned = params.unassigned;
      var assigned = params.assigned;
      var activated = params.activated;
      var status = params.status;
      var product = params.product;
      var policy = params.policy;
      var group = params.group;
      var user = params.user;
      var machine = params.machine;

      // FIXME(ezekg) Ember doesn't support object key/value query params
      switch (true) {
        case params['activations[eq]'] != null:
          activations = { eq: params['activations[eq]'] };
          break;
        case params['activations[gt]'] != null:
          activations = { gt: params['activations[gt]'] };
          break;
        case params['activations[gte]'] != null:
          activations = { gte: params['activations[gte]'] };
          break;
        case params['activations[lt]'] != null:
          activations = { lt: params['activations[lt]'] };
          break;
        case params['activations[lte]'] != null:
          activations = { lte: params['activations[lte]'] };
          break;
      }

      switch (true) {
        case params['expires[before]'] != null:
          expires = { before: params['expires[before]'] };
          break;
        case params['expires[after]'] != null:
          expires = { after: params['expires[after]'] };
          break;
        case params['expires[in]'] != null:
          expires = { 'in': params['expires[in]'] };
          break;
      }

      switch (true) {
        case params['activity[inside]'] != null:
          activity = { inside: params['activity[inside]'] };
          break;
        case params['activity[outside]'] != null:
          activity = { outside: params['activity[outside]'] };
          break;
        case params['activity[before]'] != null:
          activity = { before: params['activity[before]'] };
          break;
        case params['activity[after]'] != null:
          activity = { after: params['activity[after]'] };
          break;
      }

      // parse arbitrary metadata keys from transition query
      for (var param in transition.queryParams) {
        if (!param.startsWith('metadata[')) {
          continue;
        }

        if (metadata == null) {
          metadata = {};
        }

        var key = param.replace(/^metadata\[(.+)\]$/, '$1');
        var value = transition.queryParams[param];

        metadata[key] = value;
      }

      return this.store.query('license', {
        page: { number: number, size: size },
        suspended: suspended,
        expiring: expiring,
        expired: expired,
        expires: expires,
        unassigned: unassigned,
        assigned: assigned,
        activated: activated,
        activations: activations,
        status: status,
        activity: activity,
        metadata: metadata,
        product: product,
        policy: policy,
        group: group,
        user: user,
        machine: machine
      });
    }
  });
});